<template>
  <div class="menu-app-page">
    <ul>
      <li v-for="(i, k) in items" :key="`${k}a`">
        <span
          @click="gotoRoute(i)"
          :class="'menu-parent' + (menuActive === i.name ? ' active-menu-app' : '')"
          v-if="!i.children"
        >
          {{ i.title }}
        </span>
        <span class="menu-parent" v-if="i.children">
          {{ i.title }}
        </span>
        <ul v-if="i.children && i.children.length > 0">
          <li
            @click="gotoRoute(ic)"
            :class="'menu-child' + (menuActive === ic.name || currentPage === ic.name ? ' active-menu-app' : '')"
            v-for="ic in i.children"
            :key="`${ic.name}c`"
          >
            <span>
              {{ ic.title }}
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
    defaultActiveMenu: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menuActive: this.defaultActiveMenu,
      items: this.menu,
    };
  },
  computed: {
    currentPage: function() {
      return this.$route.name;
    },
    activeGroup: function() {
      let group = this.items.find(
        item =>
          item.name == this.currentPage || (item.children && item.children.find(item => item.name == this.currentPage)),
      );
      return group;
    },
  },
  methods: {
    gotoRoute(i) {
      this.menuActive = i.name;
      if (this.currentPage !== i.name) {
        this.$router.push({
          name: i.name,
        });
      }
    },
  },
  watch: {
    $route(to, from) {
      this.menuActive = to;
    },
  },
};
</script>

<style lang="scss">
.menu-app-page {
  position: fixed;
  width: 260px;
  height: 100%;
  padding-top: 50px;
  padding-left: 35px;
  // background: olive;
  border-right: 1px solid #dddd;
  ul,
  li {
    list-style: none;
    margin-top: 10px;
    // padding: 2px;
  }
  .menu-parent {
    font-size: 16px;
    font-weight: 700;

    cursor: pointer;
  }
  .menu-child {
    cursor: pointer;
  }
  .active-menu-app {
    color: var(--v-primary-base);
  }
}
</style>
